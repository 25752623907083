import React from 'react'
import Layout from "../layouts/es"
import Block from "../components/Block"

const InfoPage = (props) => (
  <Layout 
    location={props.location} 
    title="Info - Agencia creativa digital Kitzingen"
    description="Info & Contacto | Desarrollador y diseñador web: Cristian López - mail@lopezdesign.de - Kitzingen Alemania"
    >
    <Block color="white"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h1 className="shine">Info</h1>
          <h4>mail@lopezdesign.de</h4>
          <h4>lopezdesign.de</h4>
          <h4><a target="_blank" rel="noreferrer" title="instagram" href="https://www.instagram.com/cris_kreativ/">Instagram</a></h4>
          <h4><a target="_blank" rel="noreferrer" title="unsplash" href="https://unsplash.com/@cris_kreativ">Unsplash</a></h4>
          <a className="btn" title="mail@lopezdesign.de" href="mailto:mail@lopezdesign.de">E-Mail</a>
        </div>
      }
    />
    <Block color="colorCoral"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2 className="text--big"><span>Sobre</span><span>mí</span></h2>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <p>Hola, soy Cristian López. Soy un diseñador y desarrollador web con más de 3 años de experiencia con CMS Contao, 
            diseños de UI, tienda online. Empecé en una agencia de publicidad en Würzburg, Alemania en el campo del desarrollo web, 
            También hice una formación profesional como diseñador de medios digitales.
          </p>
        </div>
      }
    />
  </Layout>
)

export default InfoPage
